import './index.scss'
import Swiper from 'swiper/swiper-bundle.min.js'

$("d-flex").css('display','block')

$(function () {
    if ($(window).width()>1024) {
        $('.banner').css({ 'height': (($(window).height())) + 'px' });
        $(window).resize(function () {
            $('.banner').css({ 'height': (($(window).height())) + 'px' });
        });
    } else if ($(window).width() > 768) {
        $('.banner').css('height','500px');
        $(window).resize(function () {
            $('.banner').css('height', '500px');
        });
    }else {
        $('.banner').css('height', '300px');
        $(window).resize(function () {
            $('.banner').css('height', '300px');
        });
    }
    
});
var bannernav = $(".banner-nav div h2 a");
var bannerpic = $(".banner .pic div");
bannernav.mouseover(function () {
    bannernav.parent().parent('div').attr("class", "");
    bannerpic.css("opacity", "0");
    $(this).parent().parent('div').addClass("active");
    bannerpic.eq($(this).parent().parent('div').index()).css("opacity", "1");
})
$(".banner-nav div a").mouseover(function () {
    $(".line div").stop(true, true).animate({
        'top': + $(this).parent().parent('div').position().top + 10 + 'px'
    }, 100, 'swing');
})

//页面滚动监听
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
    var scroH = $(document).scrollTop(); //滚动高度
    var viewH = $(window).height(); //可见高度
    var contentH = $(document).height(); //内容高度

    //滚动文字效果
    //判断页面是上滚还是下滚
    var delta = scroH - beforeScroH;
    if (scroH >= $(".about").offset().top - 400) {
        if (delta > 0) {
            //下滚
            $('.slash').css('transform', 'rotate(' + scroH / 80 + 'deg)')
        }
        if (delta < 0) {
            //上滚
            $('.slash').css('transform', 'rotate(' + scroH / 80 + 'deg)')
        }
    }
    beforeScroH = scroH;
});
var swiper = new Swiper(".mySwiper", {
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
        renderFraction: function (currentClass, totalClass) {
            return '<span class="' + currentClass + '"></span>' +
                ' <span> / </span> ' +
                '<span class="' + totalClass + '"></span>';
        },
    },
    navigation: {
        nextEl: ".container .swiper-button-next",
        prevEl: ".container .swiper-button-prev",
    },
});

// 产品大标题切换
var pronav = $(".pro-nav a");
var prolib = $(".content .pro-content");
pronav.mouseover(function () {
    pronav.attr("class", "");
    prolib.css("display", "none");
    $(this).addClass("active");
    prolib.eq($(this).index()).css("display", "block");
})

// 产品小标题切换
var cpnav1 = $(".pro1 .little-nav a");
var cplib1 = $(".pro1 .container");
cpnav1.mouseover(function () {
    cpnav1.attr("class", "");
    cplib1.css("display", "none");
    $(this).addClass("active");
    cplib1.eq($(this).index()).css("display", "block");
})
var cpnav2 = $(".pro2 .little-nav a");
var cplib2 = $(".pro2 .container");
cpnav2.mouseover(function () {
    cpnav2.attr("class", "");
    cplib2.css("display", "none");
    $(this).addClass("active");
    cplib2.eq($(this).index()).css("display", "block");
})
var cpnav3 = $(".pro3 .little-nav a");
var cplib3 = $(".pro3 .container");
cpnav3.mouseover(function () {
    cpnav3.attr("class", "");
    cplib3.css("display", "none");
    $(this).addClass("active");
    cplib3.eq($(this).index()).css("display", "block");
})

$(".little-nav span").css("width", $(".little-nav a.active").width() + 'px')
$(".little-nav span").stop(true, true).animate({
    'left': $(".pro1 .active").position().left + 'px'
}, 50, 'swing');
$(".little-nav a").mouseover(function () {
    $(this).parent().find('span').css("width", $(this).width() + 'px')
    $(this).parent().find('span').stop(true, true).animate({
        'left': $(this).position().left + 'px'
    }
        , 50, 'swing');
})